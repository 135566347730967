import React from "react"
import {
  Box, Flex, HStack, Stack, Text,
} from "@chakra-ui/react"
import { Translations } from "sharedTypes"
import SubmissionCard from "pages/EventShow/Submissions/FormSubmissions/FormSubmissionsDetailView/SubmissionCard"
import Icon from "components/elements/Icon"
import Link from "components/elements/Link"

const PlusOnes = ({
  translations,
  primaryColor,
  plusOnes,
}: {
  translations: Translations
  primaryColor: string
  plusOnes: { id: string; name: string; email: string }[]
}) => (
  <SubmissionCard
    header={(
      <Flex
        justify="space-between"
        fontSize="xl"
        fontWeight="semibold"
        w="100%"
      >
        <HStack fontWeight="semibold" fontSize="xl">
          <Icon icon="guest-group" size={6} mr={1} />
          <Text>{translations.plusOnesHeader}</Text>
        </HStack>
      </Flex>
      )}
  >
    <Show
      plusOnes={plusOnes}
      translations={translations}
      primaryColor={primaryColor}
    />
  </SubmissionCard>
)

const Show = ({
  plusOnes,
  translations,
  primaryColor,
}: {
  primaryColor: string
  plusOnes: { id: string; name: string; email: string }[]
  translations: Translations
}) => (
  <>
    <Box mb={plusOnes.length > 0 ? 6 : 0} fontWeight="semibold">
      <Text>{translations.rsvpPlusOneLabel}</Text>
      <Text color={primaryColor}>
        {plusOnes.length === 0
          ? translations.rsvpPlusOneNo
          : `${translations.rsvpPlusOneYes} (+${plusOnes.length})`}
      </Text>
    </Box>
    <Stack spacing={4}>
      {plusOnes.map((plusOne, index) => (
        <Link
          key={index}
          href={`${import.meta.env.VITE_WEBSITE_HOST}/g/${plusOne.id}`}
          color="gray.700"
          m="3px"
          py={1}
          _hover={{
            borderRadius: "lg",
            textDecoration: "none",
            bgColor: "gray.50",
            outlineColor: primaryColor,
            outlineWidth: 1,
          }}
          px={5}
          role="group"
          target="_blank"
        >
          <Flex key={index} minH={14} justify="space-between">
            <Flex alignItems="center">
              <Text
                w="48px"
                lineHeight={6}
                mr={3}
                fontSize="xl"
                fontWeight="semibold"
              >
                #{index + 1}
              </Text>
              <Box>
                <Text lineHeight={6}>{plusOne.name}</Text>
                <Text>{plusOne.email}</Text>
              </Box>
            </Flex>
            <Flex align="center" color={primaryColor}>
              <Icon icon="link-external" />
            </Flex>
          </Flex>
        </Link>
      ))}
    </Stack>
  </>
)

export default PlusOnes
